import PropTypes from 'prop-types';
import React from 'react';
import logo from '../assets/logo.svg';
// import GetStartedForm from './GetStartedForm';
import Cluster from './primitives/Cluster';
import Section from './Section';

const Header = ({
  title,
}) => (
  <Section as="header">
    <Cluster justify="space-between">
      <a href="/">
        <h3>{title}</h3>
      </a>
    </Cluster>
  </Section>
);

Header.propTypes = {
  author: PropTypes.string,
};

Header.defaultProps = {
  author: '',
};

export default Header;
