import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
} from 'react';
import styled, {
  withTheme,
} from 'styled-components';

const Component = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.space[props.space]};
  grid-template-columns: 100%;
  align-items: ${props => props.align};

  &.aboveMin {
    grid-template-columns: ${props => `repeat(auto-fit, minmax(${props.theme.measure[props.min]}, 1fr))`};
  }

  @supports (width: min(20rem, 100%)) {
    .grid {
      grid-template-columns: repeat(auto-fit, minmax(min(20rem, 100%), 1fr));
    }
  }
`;

const Grid = ({
  as,
  className,
  children,
  space,
  min,
  align,
  theme,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if ('ResizeObserver' in window) {
      // Create a proxy element to measure and convert
      // the `min` value (which might be em, rem, etc) to `px`
      const test = document.createElement('div');
      test.style.width = theme.measure[min];
      ref.current.appendChild(test);
      const minToPixels = test.offsetWidth;
      ref.current.removeChild(test);

      const resizeObserver = new ResizeObserver((entries) => {
        entries.map((entry) => {
          // Get the element's current dimensions
          const {
            contentRect,
          } = entry;
          // `true` if the container is wider than the minimum
          const isWide = contentRect.width > minToPixels;
          // toggle the class conditionally
          ref.current.classList.toggle('aboveMin', isWide);
        });
      });

      resizeObserver.observe(ref.current);

      return () => resizeObserver.disconnect();
    }
  }, [
    min,
    theme.measure,
  ]);

  return (
    <Component
      as={as}
      className={`${className} aboveMin`}
      ref={ref}
      space={space}
      min={min}
      align={align}
    >
      {children}
    </Component>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
  ]),
  min: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
  ]),
  align: PropTypes.string,
};

Grid.defaultProps = {
  space: 3,
  min: 1,
  align: '',
};

export default withTheme(Grid);
