import {
  LinkedinOutlined,
  TwitterOutlined,
} from '@ant-design/icons';
import React from 'react';
import logo from '../assets/logo.svg';
import nzitLogo from '../assets/logos/nzit.svg';
import TheClinicianLogo from '../assets/logos/theclinician.inline.svg';
import privacyPolicy from '../assets/pdf/privacy-policy.pdf';
import termsAndConditions from '../assets/pdf/terms-and-conditions.pdf';
import Button from './Button';
import Center from './primitives/Center';
import Cluster from './primitives/Cluster';
import Grid from './primitives/Grid';
import Stack from './primitives/Stack';
import Section from './Section';

const Footer = () => {
  return (
    <Section as="footer">
      <Stack className="text-small text-center" space={5}>
        <Center intrinsic>
          <Stack>
            <Stack space={1}>
              <TheClinicianLogo
                style={{
                  fill: 'rgba(0,0,0,0.85)',
                }}
              />
              <Cluster as="ul" space={2} justify="center">
                <li>
                  <Button
                    href="//twitter.com/theclinicianpro"
                    target="_blank"
                    rel="noopener noreferrer"
                    shape="circle"
                    icon={<TwitterOutlined />}
                  />
                </li>
                <li>
                  <Button
                    href="//www.linkedin.com/company/the-clinician"
                    target="_blank"
                    rel="noopener noreferrer"
                    shape="circle"
                    icon={<LinkedinOutlined />}
                  />
                </li>
              </Cluster>
            </Stack>
            <p>
              ©
              {' '}
              {new Date().getFullYear()}
              {' '}
              <a
                href="https://theclinician.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Clinician Ltd.
              </a>
            </p>
          </Stack>
        </Center>
      </Stack>
    </Section>
  );
};

export default Footer;
