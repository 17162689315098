import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const Component = styled.div`
  > * {
    display: flex;
    flex-wrap: ${props => props.wrap};
    margin: ${props => `calc(${props.theme.space[props.space]} / 2 * -1)`};
    ${({
    justify,
    align,
  }) => `
      justify-content: ${justify};
      align-items: ${align};
    `};
  }

  > * > * {
    margin: ${props => `calc(${props.theme.space[props.space]} / 2)`};
  }
`;

const Wrapper = styled.div``;

const Cluster = ({
  as,
  children,
  space,
  justify,
  align,
  wrap,
}) => {
  return (
    <Component space={space} justify={justify} align={align} wrap={wrap}>
      <Wrapper as={as}>{children}</Wrapper>
    </Component>
  );
};

Cluster.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
  ]),
  justify: PropTypes.string,
  align: PropTypes.string,
  wrap: PropTypes.oneOf([
    'wrap',
    'nowrap',
  ]),
};

Cluster.defaultProps = {
  space: 3,
  justify: 'flex-start',
  align: 'center',
  wrap: 'wrap',
};

export default Cluster;
