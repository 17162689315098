export const setUser = (userId) => {
  if (!window._paq) {
    return;
  }

  if (!userId) {
    return;
  }

  window._paq.push([
    'setUserId',
    userId,
  ]);
  window._paq.push([
    'trackPageView',
  ]);
};

export const trackEvent = (category, action, name, value) => {
  if (!window._paq) {
    return;
  }

  window._paq.push([
    'trackEvent',
    category,
    action,
    name,
    value,
  ]);
};
