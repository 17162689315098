// Purpose of this component to lazy load ANTD DatePicker
// Using InputDate is fine but we also use RangePicker that depends on DatePicker
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  Suspense,
  lazy,
  useMemo,
} from 'react';
import {
  toYearMonthDay,
} from '../utils/date';
import 'antd/es/date-picker/style/css';

const LazyDatePicker = lazy(() => import(/* webpackChunkName: "DatePicker" */ 'antd/es/date-picker'));

const {
  RangePicker,
} = LazyDatePicker;

const parseDate = (value) => {
  const m = moment(value, moment.ISO_8601);

  if (m.isValid()) {
    return m;
  }

  return null;
};

const formatDate = (date) => {
  if (!date) {
    return '';
  }

  return toYearMonthDay(date);
};

const DatePicker = ({
  value,
  onChange,
  'data-testid': testId,
}) => {
  const props = useMemo(() => {
    const obj = {
      onChange: date => onChange(formatDate(date)),
    };

    if (value) {
      obj.value = parseDate(value);
    }

    return obj;
  }, [
    onChange,
    value,
  ]);

  return (
    <LazyDatePicker
      data-testid={testId}
      style={{
        width: '100%',
      }}
      format="DD/MM/YYYY"
      placeholder="dd/mm/yyyy"
      {...props}
    />
  );
};

DatePicker.propTypes = {
  'data-testid': PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  'data-testid': 'date-picker',
};

export default props => (
  <Suspense fallback={<div>loading...</div>}>
    <DatePicker {...props} />
  </Suspense>
);

export {
  RangePicker,
};
