import {
  useField,
} from 'formik';
import React from 'react';
import styled from 'styled-components';
import FormItem from '../FormItem';
import Input from '../Input';
import TextArea from '../TextArea';

const StyledInput = styled(Input)`
  &.ant-input-group-wrapper {
    .ant-input {
      border-right: 0;
      height: 42px;
    }

    .ant-input-group-addon {
      padding: 0;
      border: 0;

      button {
        border: 0;
        cursor: pointer;
        padding: ${props => props.theme.space[2]}
          ${props => props.theme.space[3]};
        background-color: ${props => props.theme.color.primary};
        color: ${props => props.theme.color.light};
        border-top-right-radius: ${props => props.theme.border.radius[2]};
        border-bottom-right-radius: ${props => props.theme.border.radius[2]};
        transition: background 0.2s ease-in-out;

        &:hover,
        &:focus {
          /* TODO: define primary / accent hover colours better */
          background-color: #6d77d6;
        }
      }
    }
  }
`;

const FormFieldText = ({
  label,
  ...props
}) => {
  const [
    field,
    meta,
  ] = useField(props);

  return (
    <FormItem
      labelCol={{
        span: 24,
      }}
      label={label}
      required={props.required}
      help={meta.touched && meta.error}
      validateStatus={meta.touched && meta.error ? 'error' : ''}
    >
      {props.type === 'textarea' ? (
        <TextArea {...field} {...props} />
      ) : (
        <StyledInput {...field} {...props} />
      )}
    </FormItem>
  );
};

export default FormFieldText;
