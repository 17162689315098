import styled from 'styled-components/macro';
import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  theme,
} from '../utils/cssHelpers';

// TODO: Update with proper styles after merging provider app redesign branch
const StyledInput = styled.input`
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
  padding-right: ${theme('rhythm.small')};
  padding-left: ${theme('rhythm.small')};
  border-radius: ${theme('border.radius.2')};
  width: 100%;

  ::-webkit-inner-spin-button {
    display: none;
  }

  /* height to match antd input fields */
  height: 32px;
`;

const NativeDatePicker = ({
  value,
  onChange,
  'data-testid': testId,
}) => {
  const props = useMemo(
    () => ({
      onChange: event => onChange(event.target.value),
    }),
    [
      onChange,
    ],
  );

  return (
    <StyledInput
      data-testid={testId}
      type="date"
      value={value}
      {...props}
    />
  );
};

NativeDatePicker.propTypes = {
  'data-testid': PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

NativeDatePicker.defaultProps = {
  'data-testid': 'date-picker',
};

export default NativeDatePicker;
