import {
  getResponsiveBreakpoint,
} from './media';

const base = 1.5;
const scaleBase = 1.414;

export default {
  line: {
    height: {
      base,
    },
  },
  color: {
    primary: '#616cdc',
    accent: '#fd7e14',
    light: '#fff',
  },
  border: {
    radius: {
      2: '0.75em',
    },
  },
  breakpoints: {
    mobile: getResponsiveBreakpoint(480),
    tablet: getResponsiveBreakpoint(768),
    laptop: getResponsiveBreakpoint(1200),
    desktop: getResponsiveBreakpoint(1600),
  },
  space: {
    0: 0,
    1: `${base * 0.25}rem`,
    2: `${base * 0.5}rem`,
    3: `${base}rem`,
    4: `${base * 2}rem`,
    5: `${base * 2.5}rem`,
  },
  scale: {
    0: `${scaleBase ** 0}rem`,
    1: `${scaleBase ** -2}rem`,
    2: `${scaleBase ** -1}rem`,
    3: `${scaleBase ** 1}rem`,
    4: `${scaleBase ** 2}rem`,
    5: `${scaleBase ** 3}rem`,
  },
  measure: {
    0: '0ch',
    1: '30ch',
    2: '50ch',
    3: '65ch',
    4: '80ch',
    5: '95ch',
  },
};
