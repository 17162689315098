import {
  useField,
} from 'formik';
import React from 'react';
import FormItem from '../FormItem';
import Select from '../Select';

const FormFieldSelect = ({
  label,
  options,
  ...props
}) => {
  const [
    field,
    meta,
  ] = useField(props);

  return (
    <FormItem
      labelCol={{
        span: 24,
      }}
      label={label}
      required={props.required}
      help={meta.touched && meta.error}
      validateStatus={meta.touched && meta.error ? 'error' : ''}
    >
      <Select
        // id={label}
        // autoComplete="nope"
        // showSearch
        // optionFilterProp="children"
        {...field}
        {...props}
      >
        {options.map(({
          value,
          label,
        }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );
};

export default FormFieldSelect;
