import React from 'react';
import GetStartedForm from './GetStartedForm';
import Center from './primitives/Center';
import Section from './Section';

const Layout = () => {
  return (
    <Section>
      <Center>
        <GetStartedForm />
      </Center>
    </Section>
  );
};

export default Layout;
