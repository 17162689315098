import isNull from 'lodash/isNull';
import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  formFieldPropTypesInput,
} from '../utils/propTypes';
import NativeDatePicker from './NativeDatePicker';
import FallbackDatePicker from './DatePicker';

const getIsNativeDateSupported = function () {
  const input = document.createElement('input');
  const value = 'a';

  input.setAttribute('type', 'date');
  input.setAttribute('value', value);

  return input.value !== value;
};

const InputDate = ({
  input,
  'data-testid': testId,
}) => {
  const [
    isNativeDateSupported,
    setIsNativeDateSupported,
  ] = useState(null);

  useEffect(() => {
    if (getIsNativeDateSupported()) {
      setIsNativeDateSupported(true);
    } else {
      setIsNativeDateSupported(false);
    }
  }, []);

  if (isNull(isNativeDateSupported)) {
    return null;
  }

  return isNativeDateSupported ? (
    <NativeDatePicker
      {...input}
      data-testid={testId}
    />
  ) : (
    <FallbackDatePicker
      {...input}
      data-testid={testId}
    />
  );
};

InputDate.propTypes = {
  'data-testid': PropTypes.string,
  input: formFieldPropTypesInput,
};

InputDate.defaultProps = {
  'data-testid': 'date-picker',
  input: null,
};

export default InputDate;
