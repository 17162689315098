import React from 'react';
import styled from 'styled-components';

const Component = styled.section`
  padding: ${props => props.theme.space[5]} ${props => props.theme.space[3]};
`;

const Wrapper = styled.div`
  /* TODO: Remove magic number */
  max-width: 1150px;
  margin-right: auto;
  margin-left: auto;
`;

const Section = ({
  as,
  id,
  className,
  children,
}) => {
  return (
    <Component as={as} id={id} className={className}>
      <Wrapper>{children}</Wrapper>
    </Component>
  );
};

export default Section;
