import React from 'react';
// import About from '../components/About';
// import Benefits from '../components/Benefits';
import GetStartedForm from '../components/GetStartedForm';
import Form from '../components/Form';
// import Hero from '../components/Hero';
import Layout from '../components/Layout';
// import Pricing from '../components/Pricing';
// import SEO from '../components/SEO';
// import SetUp from '../components/SetUp';
// import Steps from '../components/Steps';

const IndexPage = () => (
  <Layout>
    <Form />
  </Layout>
);

export default IndexPage;
