import {
  css,
} from 'styled-components';

const getSizeFromBreakpoint = (breakpointValue, breakpoints = {}) => {
  if (!breakpoints[breakpointValue]) {
    return console.error('No valid breakpoint or size specified for media.');
  }

  return breakpoints[breakpointValue];
};

const generateMedia = () => {
  const BREAKPOINTS_OFFSET = '0.0675rem';

  const lessThan = breakpoint => (...args) => css`
    @media (max-width: ${props => `calc(${getSizeFromBreakpoint(
    breakpoint,
    props.theme.breakpoints,
  )} - ${BREAKPOINTS_OFFSET})`}) {
      ${css(...args)}
    }
  `;

  const greaterThan = breakpoint => (...args) => css`
    @media (min-width: ${props => getSizeFromBreakpoint(breakpoint, props.theme.breakpoints)}) {
      ${css(...args)}
    }
  `;

  return {
    lessThan,
    greaterThan,
  };
};

export const getResponsiveBreakpoint = breakpoint => `${breakpoint / 16}rem`;

export default generateMedia();
