import {
  useField,
} from 'formik';
import React from 'react';
import FormItem from '../FormItem';
import InputDate from '../InputDate';

const FormFieldDate = ({
  label,
  ...props
}) => {
  const [
    field,
    meta,
  ] = useField(props);

  return (
    <FormItem labelCol={{
        span: 24,
      }}
      label={label}
      required={props.required}
      help={meta.touched && meta.error}
      validateStatus={meta.touched && meta.error ? 'error' : ''}
    >
      <InputDate  {...field} {...props} required name="dob" />
    </FormItem>
  );
};

export default FormFieldDate;