import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Component = styled.div`
  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: ${props => props.theme.space[props.space]};
  }
`;

const Stack = ({
  as,
  className,
  space,
  children,
}) => {
  return (
    <Component as={as} className={className} space={space}>
      {children}
    </Component>
  );
};

Stack.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
  ]),
};

Stack.defaultProps = {
  space: 3,
};

export default Stack;
