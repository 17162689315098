import {
  createGlobalStyle,
} from 'styled-components';
import media from './media';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 93.75%;

    ${media.greaterThan('mobile')`
      font-size: 100%;
    `}

    ${media.greaterThan('desktop')`
      font-size: 112.5%;
    `}
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: ${props => props.theme.line.height.base}rem;
    color: rgba(0,0,0,0.75);
  }

  /* Headings */

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    color: rgba(0,0,0,0.85);
    letter-spacing: 0.02em;
    margin: 0;
  }

  html {
    h1 {
      font-size: ${props => props.theme.scale[5]};
      line-height: ${props => props.theme.space[5]};
      max-width: 25ch;
    }

    h2 {
      font-size: ${props => props.theme.scale[4]};
      line-height: ${props => props.theme.space[4]};
    }

    h3 {
      font-size: ${props => props.theme.scale[3]};
      line-height: ${props => props.theme.space[4]};
    }
  }

  /* Paragraph */

  p {
    margin: 0;

    + p {
      margin-top: ${props => props.theme.space[3]};
    }

    &.faded {
      color: rgba(0,0,0,0.5);
    }

    &.big {
      font-size: ${props => props.theme.scale[3]};
      line-height: 1.5;
      font-weight: 700;
    }

    &.subheading {
      font-size: 1.125em;
      line-height: 1.5;
    }
  }

  /* List */

  ul,
  ol {
    list-style: none;
    padding-left: 0;

    li {

      + li {
        /* margin-top: ${props => props.theme.space[3]}; */
      }
    }
  }

  /* Link */

  a {
    color: ${props => props.theme.color.accent};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  /* Image */

  img {
    max-width: 100%;
    height: auto;
  }

  /* Readable text */

  * {
    max-width: ${props => props.theme.measure[3]};
  }

  html,
  body,
  div,
  header,
  nav,
  ul,
  ol,
  li,
  main,
  section,
  footer {
    max-width: none;
  }

  /* Helpers */

  .text-small {
    font-size: ${props => props.theme.scale[2]};
  }

  .hide-small {
    ${media.lessThan('tablet')`
      display: none;
    `}
  }

  /* Rewrite ANTD */

  .ant-btn-round.ant-btn-lg {
    padding-right: ${props => props.theme.space[5]};
    padding-left: ${props => props.theme.space[5]};
  }

  .ant-select-selection-item {
    line-height: 36px !important;
  }
`;
