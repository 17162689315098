import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Component = styled.div`
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: ${props => props.theme.measure[props.max]};

  ${props => props.space &&
    `
    padding-right: ${props.theme.space[props.space]};
    padding-left: ${props.theme.space[props.space]};
  `};

  ${props => props.intrinsic &&
    `
    display:flex;
    flex-direction: column;
    align-items: center;
  `};

  ${props => props.alignInline &&
    `
    text-align: center;
  `};
`;

const Center = ({
  children,
  max,
  space,
  intrinsic,
  alignInline,
}) => (
  <Component
    max={max}
    space={space}
    intrinsic={intrinsic}
    alignInline={alignInline}
  >
    {children}
  </Component>
);

Center.propTypes = {
  children: PropTypes.node,
  space: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
  ]),
  // TODO: Change max to something different as it gets passes to the DOM
  max: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
  ]),
  intrinsic: PropTypes.bool,
  alignInline: PropTypes.bool,
};

Center.defaultProps = {
  children: null,
  space: 0,
  max: 4,
  intrinsic: false,
  alignInline: false,
};

export default Center;
