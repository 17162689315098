import {
  Form as FormFormik,
  Formik,
} from 'formik';
import forEach from 'lodash/forEach';
import React, {
  useEffect,
  useState,
} from 'react';
import moment from 'moment';
import {
  KEY_reCAPTCHA,
} from '../constants/keys';
import {
  SCHEMA_INTERNATIONAL_PHONE,
  SCHEMA_SG_NRIC_FIN,
  SCHEMA_RX__EMAIL,
} from '../constants/schema';
import {
  getDefaultLanguage,
  getDefaultTimezone,
} from '../utils/helpers';
import {
  isoLangsOptions,
} from '../utils/isoLangs';
import {
  setUser as setMatomoUser,
  trackEvent as trackMatomoEvent,
} from '../utils/matomo';
import notification from '../utils/notification';
// import {
//   timezonesOptions,
// } from '../utils/timezones';
import Button from './Button';
import FormFieldSelect from './FormField/FormFieldSelect';
import FormFieldText from './FormField/FormFieldText';
import Center from './primitives/Center';
import Stack from './primitives/Stack';
import Result from './Result';
import FormFieldDatePicker from './FormField/FormFieldDatePicker';

const GetStartedForm = () => {
  useEffect(() => {
    trackMatomoEvent('Form', 'Sign Up Open');
  }, []);

  const [
    isSent,
    setIsSent,
  ] = useState(false);

  const currentDay = moment().format('YYYY-MM-DD');

  const [
    getDob,
    setDob,
  ] = useState(currentDay);

  const initialValues = {
    timezone: getDefaultTimezone(),
    language: getDefaultLanguage(),
  };

  const onSubmit = (values) => {
    const dob = getDob;
    const formData = new FormData();

    formData.append('dob', dob);
    forEach(values, (value, key) => {
      formData.append(key, value);
    });

    fetch('https://lzyf0s7rhf.execute-api.ap-southeast-1.amazonaws.com/production/sendform', {
      method: 'POST',
      body: formData,
    }).then(() => {
      setIsSent(true);
    }).catch(() => {
      setIsSent(false);
      notification.error({
        message: 'Something went wrong when signing up',
        description: 'Please try again',
        duration: 0,
      });
    });
  };

  const onDateChange = (value) => {
    setDob(value);
  };

  const validate = (values) => {
    const errors = {};

    if (!SCHEMA_INTERNATIONAL_PHONE.re.test(values.phoneNumber)) {
      errors.phoneNumber = `Phone number should ${SCHEMA_INTERNATIONAL_PHONE.to}`;
    }

    if (!SCHEMA_SG_NRIC_FIN.re.test(values.fin)) {
      errors.fin = `FIN should ${SCHEMA_SG_NRIC_FIN.to}`;
    } else {
      if (values.fin !== values.finConfirm) {
        errors.finConfirm = `FIN doesn't match`;
      }
    }

    return errors;
  };

  return isSent ? (
    <Result
      status="success"
      title="Successfully signed up!"
      subTitle="Thank you. We have sent an SMS to your phone for your first questionnaire."
    />
  ) : (
    <Stack>
      <h2>Expo - Sign Up</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        enableReinitialize
      >
        {props => (
          <FormFormik>
            <Stack>
              <FormFieldText label="FIN" placeholder="FIN" name="fin" required />
              <FormFieldText label="Re-enter FIN" placeholder="FIN Confirmation" name="finConfirm" required />
              <FormFieldDatePicker
                label= "Date of Birth"
                name="dob"
                required
                input={{
                  value: getDob,
                  onChange: onDateChange,
                }}
              />
              <FormFieldText
                label="Mobile Number"
                placeholder="Mobile No."
                name="phoneNumber"
                type="tel"
                required
              />
              <Center intrinsic>
                <Button
                  htmlType="submit"
                  type="primary"
                  shape="round"
                  size="large"
                >
                  Sign Up
                </Button>
              </Center>
            </Stack>
          </FormFormik>
        )}
      </Formik>
    </Stack>
  );
};

export default GetStartedForm;
