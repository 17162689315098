import {
  graphql,
  useStaticQuery,
} from 'gatsby';
import 'normalize.css';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import {
  ThemeProvider,
} from 'styled-components';
import GlobalStyle from '../ui/GlobalStyle';
import theme from '../ui/theme';
import '../ui/theme.less';
import Footer from './Footer';
import Header from './Header';

const Layout = ({
  children,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
